package com.ahmedadeltito.website.styles

import com.ahmedadeltito.website.models.Theme
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import org.jetbrains.compose.web.css.ms

val NavigationFooterItemStyle = CssStyle {
    base {
        Modifier
            .styleModifier {
                property("--bs-link-color", Colors.White)
            }
            .color(color = Colors.White)
            .transition(Transition.of(property = "color", duration = 200.ms))
    }
    hover {
        Modifier
            .styleModifier {
                property("--bs-link-hover-color", Theme.DarkGray.rgb)
            }
            .color(color = Theme.DarkGray.rgb)
    }
}