package com.ahmedadeltito.website.models

import com.ahmedadeltito.website.util.Resources

enum class Service(
    val icon: String,
    val imageDesc: String,
    val title: String,
    val description: String
) {
    Mobile(
        icon = Resources.Icon.MOBILE,
        imageDesc = "Mobile Icon",
        title = "Native Android and iOS Apps",
        description = "I specialize in developing robust and scalable native mobile apps for both Android and iOS platforms. From architectural design to app deployment, I ensure each app is maintainable, testable, and aligned with both technical and business goals. Whether it’s creating a new app from scratch or enhancing an existing one, I focus on delivering seamless user experiences and high performance."
    ),
    KMP(
        icon = Resources.Icon.KMP,
        imageDesc = "Kotlin Multiplatform Development Icon",
        title = "KMP for Domain Layer & Server-Side Development",
        description = "Harness the power of Kotlin Multiplatform (KMP) to build shared domain layers for both Android and iOS apps. My expertise extends to developing efficient server-side solutions using Ktor, enabling seamless integration and consistent performance across platforms. This approach reduces code duplication and accelerates development time, ensuring a cohesive product experience."
    ),
    CMP(
        icon = Resources.Icon.CMP,
        imageDesc = "Compose Multiplatform Development Icon",
        title = "Compose UI for Android, iOS, and Web",
        description = "Experience the future of UI development with Compose Multiplatform (CMP). I create native, declarative UIs for Android, iOS, and Web using Compose, ensuring a unified look and feel across all platforms. This innovative approach allows for faster development cycles and a more consistent user experience, leveraging the strengths of each platform while maintaining a common codebase."
    ),
    MockingInterviews(
        icon = Resources.Icon.MOCKING_INTERVIEWS,
        imageDesc = "Mocking Interviews Icon",
        title = "Comprehensive Mock Interviews",
        description = "Prepare for your next technical interview with confidence. I conduct thorough mock interviews, covering over 10 technical topics including coding challenges and system design. My realistic interview simulations help candidates from around the world secure positions at top tech companies by providing constructive feedback and strategies for success."
    ),
    Mentoring(
        icon = Resources.Icon.MENTORING,
        imageDesc = "Mentoring Icon",
        title = "Personalized Mentorship for Techies",
        description = "Achieve your career goals with my tailored mentorship programs. Having mentored over 150 tech professionals, I provide guidance on career progression, learning new tools and frameworks, job applications, CV reviews, and more. Whether you’re just starting out or looking to advance in your career, I offer the insights and support needed to reach your full potential."
    ),
    Consulting(
        icon = Resources.Icon.CONSULTING,
        imageDesc = "Consulting Icon",
        title = "Strategic Consulting for Mobile Startups",
        description = "Transform your startup with expert mobile tech consulting. I have assisted more than 5 startups in the Middle East with scaling their tech teams, leading mobile projects, hiring candidates that fit their culture and technical needs, and building scalable, maintainable mobile architectures. My strategic approach ensures your startup can adapt to business changes and continue to grow."
    )
}