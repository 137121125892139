package com.ahmedadeltito.website.sections

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.components.SocialLinks
import com.ahmedadeltito.website.models.Section
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.styles.MainButtonStyle
import com.ahmedadeltito.website.styles.MainImageStyle
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.ahmedadeltito.website.util.Constants.SECTION_WIDTH
import com.ahmedadeltito.website.util.Resources
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun MainSection() {
    Box(
        modifier = Modifier
            .id(value = Section.Home.id)
            .fillMaxWidth()
            .maxWidth(size = SECTION_WIDTH.px)
            .classNames("section-container"),
        contentAlignment = Alignment.TopCenter
    ) {
        MainContent()
    }
}

@Composable
fun MainContent() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(color = Theme.SectionBackground.rgb)
            .padding(top = 80.px),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Column(
            modifier = Modifier.fillMaxSize().padding(topBottom = 40.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SimpleGrid(
                modifier = Modifier.fillMaxSize(percent = 90.percent),
                numColumns = numColumns(base = 1, sm = 1, md = 1, lg = 2, xl = 2)
            ) {
                if (breakpoint >= Breakpoint.LG) {
                    MainText(breakpoint = breakpoint)
                    MainImage(breakpoint = breakpoint)
                } else {
                    MainImage(breakpoint = breakpoint)
                    MainText(breakpoint = breakpoint)
                }
            }
        }
    }
}

@Composable
fun MainText(breakpoint: Breakpoint) {
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (breakpoint >= Breakpoint.MD) {
            Column(
                modifier = Modifier
                    .margin(right = 60.px)
                    .padding(topBottom = 20.px)
                    .minWidth(40.px),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                SocialLinks()
            }
        }
        Column {
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(topBottom = 0.px)
                    .fontSize(if (breakpoint >= Breakpoint.XL) 40.px else 25.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Primary.rgb)
                    .toAttrs()
            ) {
                Text("Hello, I'm")
            }
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(top = 20.px, bottom = 0.px)
                    .fontSize(if (breakpoint >= Breakpoint.XL) 60.px else 40.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()
            ) {
                Text("Ahmed, aka Tito!")
            }
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(top = 10.px, bottom = 5.px)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()
            ) {
                Text("Software Engineer | Mobile Architect | Speaker | Mentor")
            }
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(top = 10.px, bottom = 25.px)
                    .fillMaxWidth()
                    .fontSize(15.px)
                    .fontStyle(FontStyle.Italic)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Secondary.rgb)
                    .toAttrs()
            ) {
                Text(
                    "I've worked for big companies like Workday, Zendesk, Swvl, and Bevy. " +
                            "I have also spoken at various tech events where 2,000+ attended and mentored hundreds of techies allover the world!"
                )
            }
            Button(
                attrs = MainButtonStyle.toModifier()
                    .height(40.px)
                    .margin(bottom = 30.px)
                    .border(width = 0.px)
                    .borderRadius(r = 10.px)
                    .cursor(Cursor.Pointer)
                    .toAttrs()
            ) {
                Link(
                    modifier = Modifier.then(FONT_FAMILY)
                        .color(Colors.White)
                        .fontSize(16.px)
                        .fontWeight(FontWeight.Normal)
                        .textDecorationLine(TextDecorationLine.None),
                    text = "Let’s Talk!",
                    path = Section.Contact.path
                )
            }
            if (breakpoint < Breakpoint.MD) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    SocialLinks()
                }
            }
        }
    }
}

@Composable
fun MainImage(breakpoint: Breakpoint) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .margin(bottom = 40.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = if (breakpoint <= Breakpoint.MD) {
            Alignment.CenterHorizontally
        } else {
            Alignment.End
        }
    ) {
        Image(
            modifier = MainImageStyle
                .toModifier()
                .fillMaxWidth(percent = 80.percent)
                .objectFit(objectFit = ObjectFit.Cover),
            src = Resources.Image.MAIN,
            alt = "Main Image"
        )
    }
}