package com.ahmedadeltito.website.models

enum class Testimonial(
    val fullName: String,
    val relationship: String,
    val review: String,
    val personalWebsite: String
) {
    BenAyesuAttahMentee(
        fullName = "Ben Ayesu-Attah 🇨🇦",
        relationship = "Mentee",
        review = "Ahmed was able to share some insight into some of the best ways to break into the industry as well as provided good recommendations on how I could improve my resume. Thank you!",
        personalWebsite = "https://www.linkedin.com/in/benjamin-ayesu-attah-211278107/"
    ),
    MouawiaHammoMentee(
        fullName = "Mouawia Hammo 🇱🇾",
        relationship = "Mentee",
        review = "I had a great talk with my mentor Ahmed, he shared his experience as Senior Software Engineer and pointed on points that I need to improve. Thank You for Your Time.",
        personalWebsite = "https://mathroda.dev/"
    ),
    ManLiMentee(
        fullName = "ManLi 🇮🇪",
        relationship = "Mentee",
        review = "Had a very nice talk with Ahmed, he was very friendly, we discussed how collaboration between designers and software engineers, and Ahmed kindly shared resources to help me as a beginner in learning Android development.",
        personalWebsite = "https://www.linkedin.com/in/manli-gao-a46aa9169/"
    ),
    KareemBadawyMentee(
        fullName = "Kareem Badawy 🇪🇬",
        relationship = "Mentee",
        review = "Having a mentor session with Ahmed has been really helpful for me. He helped a lot and gave me valuable actionable insights. I enjoyed the discussion. Thank you.",
        personalWebsite = "https://karembadawy.com/"
    ),
    BahaaIbrahimMentee(
        fullName = "Bahaa Ibrahim 🇪🇬",
        relationship = "Mentee",
        review = "Ahmed is very helpful person. During our session he helped me discover new areas in job applications, career path and working in Europe opportunities. I highly recommend Ahmed for any mobile engineer looking for the next big step in his/her career.",
        personalWebsite = "https://www.linkedin.com/in/ibahaaibrahim/"
    ),
    YevheniiDanileichenkoMentee(
        fullName = "Yevhenii Danileichenko 🇺🇦",
        relationship = "Mentee",
        review = "I enjoyed this session. Ahmed was constructive and clear, pointed out issues in my CV & LinkedIn and made some useful advices on what can be done to improve them.",
        personalWebsite = "https://www.linkedin.com/in/danileichenko/"
    ),
    RaulHernandezColleague(
        fullName = "Raúl Hernández 🇪🇸",
        relationship = "Colleague",
        review = "Ahmed and I worked together at Zendesk for two years. Since I'm a Senior Staff Software Engineer, part of the Technical Leadership group in Mobile SDKs and Apps, I can relate that Ahmed was one of the most experienced Android engineers. Ahmed has worked in several technical initiatives where he demonstrated strong technical skills and collaborated in architectural discussions in addition to writing onboarding documentation for legacy systems at Zendesk.",
        personalWebsite = "https://www.linkedin.com/in/raulhernandezl/"
    ),
    RafalLenartowiczColleague(
        fullName = "Rafal Lenartowicz 🇵🇱",
        relationship = "Colleague",
        review = "I've had the pleasure of being on the same team as Ahmed, our Senior Android Engineer. Despite working in different domains—I'm in iOS and he's in Android—his dedication and expertise are unmistakable. Ahmed's commitment to our team's success and his technical prowess make him a standout colleague. His ability to drive projects to completion while maintaining high standards is truly admirable. I highly recommend Ahmed for his exceptional skills and team spirit.",
        personalWebsite = "https://www.linkedin.com/in/rlenartowicz/"
    ),
    MuhammadAbuElgheit(
        fullName = "Muhammad Abu Elgheit 🇪🇬",
        relationship = "Event Organizer",
        review = "We, at Tafra.io, had the pleasure conducting more than 2 meetups in Android Development domain and Adel was helping either as instructor/speaker or even helping with recommendations and topics. Techies who attended enjoyed these sessions a lot and we were impressed by the level of knowledge, helpfulness & professionalism he had.",
        personalWebsite = "https://www.linkedin.com/in/maelgheit/"
    ),
    MohamedAbdelHafez(
        fullName = "Mohamed AbdelHafez 🇪🇬",
        relationship = "Colleague",
        review = "I don’t know if I’m in the right position to give him a recommendation but this guy deserves it. Although he is an Android Developer but he loved to learn iOS Development and he made a great effort to be good in both. He learned a lot in iOS in a short time and proved that he is the right person who have the ability to learn new technologies faster. Beside that he has an amazing sense of humor which makes the work environment a perfect one.",
        personalWebsite = "https://www.linkedin.com/in/mhmdrmdn/"
    ),
    KarimHosny(
        fullName = "Karim Hosny 🇪🇬",
        relationship = "Colleague",
        review = "A guy who combines passion for learning and developing his self as well as understanding his tools to the lowest levels possible. Ahmed is a great guy when it comes to software engineering and building a reliable application not just android development, he works both smart and hard, and of course i can't deny his good sense of humor.",
        personalWebsite = "https://www.linkedin.com/in/kmhosny/"
    ),
    YomnaHafez(
        fullName = "Yomna Hafez 🇪🇬",
        relationship = "Colleague",
        review = "Ahmed is a well connected professional that always takes the time to support anyone in his network. He has a very impressive background and profile. He is the person I turn to on for advice as to how to harness the power of IT operations. Additionally, he has done a series of favors for me in the process, showing his sincerity and generosity. On that basis, I want to highlight Ahmed for being the Android ninja anywhere he goes",
        personalWebsite = "https://www.linkedin.com/in/yomnahafez/"
    )
}
