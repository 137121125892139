package com.ahmedadeltito.website.models

enum class Section(
    val id: String,
    val title: String,
    val subtitle: String,
    val path: String
) {
    Home(
        id = "home",
        title = "Home",
        subtitle = "",
        path = "#home"
    ),
    About(
        id = "about",
        title = "About me",
        subtitle = "How the story begins",
        path = "#about"
    ),
    Service(
        id = "service",
        title = "My Expertise",
        subtitle = "Provide Wide Range of Technical Services",
        path = "#service"
    ),
    Work(
        id = "work",
        title = "Creative Works",
        subtitle = "Check My Portfolio",
        path = "#work"
    ),
    Testimonial(
        id = "testimonial",
        title = "Testimonial",
        subtitle = "Happy Mentees/Colleagues Feedback",
        path = "#testimonial"
    ),
    Contact(
        id = "contact",
        title = "Get in Touch",
        subtitle = "Any Questions? Feel Free to Contact",
        path = "#contact"
    )
}