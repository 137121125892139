package com.ahmedadeltito.website.styles

import com.ahmedadeltito.website.models.Theme
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val WorkSectionStyle = CssStyle {
    cssRule(" > #columnParent > #boxParent > #overlay") {
        Modifier
            .width(0.px)
            .transition(Transition.of(property = "width", duration = 500.ms))
    }

    cssRule(":hover > #columnParent > #boxParent > #overlay") {
        Modifier.fillMaxWidth()
    }

    cssRule(" > #columnParent > #boxParent > #overlay > #linkIcon") {
        Modifier.visibility(Visibility.Hidden)
    }

    cssRule(":hover > #columnParent > #boxParent > #overlay > #linkIcon") {
        Modifier.visibility(Visibility.Visible)
    }

    cssRule(" > #columnParent > #workTitle") {
        Modifier
            .color(Theme.Secondary.rgb)
            .transition(
                Transition.of(property = "color", duration = 200.ms),
                Transition.of(property = "translate", duration = 200.ms)
            )
    }

    cssRule(":hover > #columnParent > #workTitle") {
        Modifier
            .color(Theme.Primary.rgb)
    }

    cssRule(" > #columnParent > #workDesc") {
        Modifier
            .color(Theme.Secondary.rgb)
            .opacity(60.percent)
            .transition(
                Transition.of(property = "color", duration = 200.ms),
                Transition.of(property = "translate", duration = 200.ms)
            )
    }

    cssRule(":hover > #columnParent > #workDesc") {
        Modifier
            .color(Theme.Black.rgb)
            .opacity(60.percent)
    }
}