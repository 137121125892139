package com.ahmedadeltito.website.components

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.models.Service
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.styles.ServiceCardStyle
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun ServiceCard(service: Service) {
    Column(
        modifier = ServiceCardStyle.toModifier()
            .fillMaxWidth()
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .padding(all = 20.px),
    ) {
        Row {
            Image(
                modifier = Modifier
                    .fillMaxSize(percent = 30.percent)
                    .margin(bottom = 20.px, right = 20.px)
                    .border(
                        width = 2.px,
                        style = LineStyle.Solid,
                        color = Theme.LighterGray.rgb
                    )
                    .backgroundColor(Theme.LighterGray.rgb)
                    .borderRadius(r = 10.px),
                src = service.icon,
                alt = service.imageDesc
            )
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .fillMaxWidth()
                    .color(Theme.Black.rgb)
                    .margin(top = 0.px, bottom = 10.px)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Normal)
                    .toAttrs()
            ) {
                Text(service.title)
            }
        }
        P(
            attrs = Modifier.then(FONT_FAMILY)
                .fillMaxWidth()
                .color(Theme.Secondary.rgb)
                .margin(top = 0.px, bottom = 0.px)
                .fontSize(14.px)
                .fontWeight(FontWeight.Normal)
                .toAttrs()
        ) {
            Text(service.description)
        }
    }
}