package com.ahmedadeltito.website.sections

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.components.SectionTitle
import com.ahmedadeltito.website.models.Section
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.ahmedadeltito.website.util.Constants.SECTION_WIDTH
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun AboutSection() {
    Box(
        modifier = Modifier
            .id(Section.About.id)
            .fillMaxWidth()
            .maxWidth(SECTION_WIDTH.px)
            .padding(topBottom = 80.px)
            .classNames("section-container"),
        contentAlignment = Alignment.Center
    ) {
        AboutContent()
    }
}

@Composable
fun AboutContent() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier
            .fillMaxWidth(percent = if (breakpoint >= Breakpoint.XL) 72.percent else 90.percent),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        AboutMe(breakpoint = breakpoint)
    }
}

@Composable
fun AboutMe(breakpoint: Breakpoint) {
    Column(
        modifier = Modifier
            .fillMaxWidth(),
        verticalArrangement = Arrangement.Center
    ) {
        SectionTitle(
            modifier = Modifier
                .fillMaxWidth(),
            section = Section.About,
            alignment = Alignment.Start
        )
        P(
            attrs = Modifier.then(FONT_FAMILY)
                .margin(top = 14.px)
                .fontSize(if (breakpoint >= Breakpoint.XL) 20.px else 16.px)
                .fontWeight(FontWeight.Normal)
                .fontStyle(FontStyle.Normal)
                .color(Theme.Secondary.rgb)
                .toAttrs()
        ) {
            Column(
                horizontalAlignment = Alignment.Start
            ) {
                Span(
                    Modifier
                        .whiteSpace(WhiteSpace.PreWrap)
                        .margin(topBottom = 14.px)
                        .toAttrs()
                ) {
                    Text("I’m Ahmed, known to many as Tito. With over 12 years of expertise in mobile development, particularly Android, I’ve honed my skills as a Senior Software Engineer.")
                }
                Span(
                    Modifier
                        .whiteSpace(WhiteSpace.PreWrap)
                        .margin(topBottom = 14.px)
                        .toAttrs()
                ) {
                    Text("Throughout my career, I’ve had the privilege of contributing to renowned companies such as ")
                    Link("https://www.workday.com/", "Workday,")
                    Text(" ")
                    Link("https://www.zendesk.com/", "Zendesk,")
                    Text(" ")
                    Link("https://www.swvl.com/", "Swvl,")
                    Text(" ")
                    Link("https://www.bevy.com/", "Bevy,")
                    Text(" ")
                    Link("https://www.letsmake.com/", "Letsmake,")
                    Text(" and ")
                    Link("https://github.com/varabyte/kobweb/", "StarWalletLabs")
                    Text(".")
                }
                Span(
                    Modifier
                        .whiteSpace(WhiteSpace.PreWrap)
                        .margin(topBottom = 14.px)
                        .toAttrs()
                ) {
                    Text("In addition to my professional engagements, I’ve served as a Mobile Consultant for several innovative startups, including ")
                    Link("https://www.mtor.co/", "Mtor,")
                    Text(" ")
                    Link("https://www.linkedin.com/company/capiter/", "Capiter,")
                    Text(" ")
                    Link("https://goodsmartegypt.com/portal", "Goodsmart,")
                    Text(" and more. My consulting experience has allowed me to shape and refine mobile strategies for emerging businesses.")
                }
                Span(
                    Modifier
                        .whiteSpace(WhiteSpace.PreWrap)
                        .margin(topBottom = 14.px)
                        .toAttrs()
                ) {
                    Text("My passion for sharing knowledge has led me to speak at various prestigious events like ")
                    Link("https://www.egypt.droidcon.com/program/ahmed-adel/", "Droidcon Egypt,")
                    Text(" ")
                    Link("https://aast.edu/en/", "Arab Academy for Science and Technology,")
                    Text(" ")
                    Link("https://www.instabug.com/", "Instabug,")
                    Text(" ")
                    Link("https://www.facebook.com/tafraio/", "Tafra,")
                    Text(" ")
                    Link("https://www.trella.app/", "Trella,")
                    Text(" ")
                    Link("https://www.linkedin.com/company/freelance-yard/about/", "Freelance Yard,")
                    Text("and ")
                    Link(
                        "https://thestartupscene.me/MenaEcosystems/Techila-Con-A-Two-Day-Tech-Conference-With-a-Twist-Come-to-Life-This-June-12th-13th",
                        "Techila Con"
                    )
                    Text(" ")
                    Text("where I had the opportunity to engage with over 2,000 tech enthusiasts.")
                }
                Span(
                    Modifier
                        .whiteSpace(WhiteSpace.PreWrap)
                        .margin(topBottom = 14.px)
                        .toAttrs()
                ) {
                    Text("Beyond speaking engagements, I take pride in mentoring hundreds of aspiring software engineers through ")
                    Link("https://adplist.org/mentors/ahmed-adel/", "ADPList")
                    Text(". ")
                    Text("My mentorship has focused on elevating their technical skills and guiding their career paths towards senior roles.")
                }
                Span(
                    Modifier
                        .whiteSpace(WhiteSpace.PreWrap)
                        .margin(topBottom = 14.px)
                        .toAttrs()
                ) {
                    Text("This journey inspired me to create an ")
                    Link(
                        "https://trello.com/templates/engineering/android-developer-roadmap-fsc44tYh",
                        "Android Roadmap"
                    )
                    Text(", a comprehensive guide that helped over 6,000 people who eager to learn Android development from scratch.")
                }
                Span(
                    Modifier
                        .whiteSpace(WhiteSpace.PreWrap)
                        .margin(top = 14.px)
                        .toAttrs()
                ) {
                    Text("Whether you’re looking for expert consultation for your tech startup, I’m ")
                    Link(path = Section.Contact.path, "here to help")
                    Text(" you navigate the complexities of mobile development and achieve your goals.")
                }
            }
        }
    }
}