package com.ahmedadeltito.website.styles

import com.ahmedadeltito.website.models.Theme
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.navigation.LinkStyle
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.selectors.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

val NavigationHeaderItemStyle = LinkStyle.addVariant {
    base {
        Modifier
            .styleModifier {
                property("--bs-link-color", Theme.Primary.rgb)
            }
            .color(color = Theme.Primary.rgb)
            .transition(Transition.of(property = "color", duration = 200.ms))
    }
    hover {
        Modifier
            .styleModifier {
                property("--bs-link-hover-color", Theme.Orange.rgb)
            }
            .color(color = Theme.Orange.rgb)
    }
}

val ActiveNavigationHeaderItemStyle = LinkStyle.addVariant {
    base {
        Modifier
            .styleModifier {
                property("--bs-link-color", Theme.Orange.rgb)
            }
            .color(color = Theme.Orange.rgb)
            .transition(Transition.of(property = "color", duration = 200.ms))
    }
}

val MainButtonStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(color = Theme.Orange.rgb)
            .padding(left = 20.px, right = 20.px)
            .transition(Transition.of(property = "backgroundColor", duration = 200.ms))
    }
    hover {
        Modifier
            .backgroundColor(color = Theme.Primary.rgb)
            .padding(left = 20.px, right = 20.px)
    }
}

val SocialMediaStyle = CssStyle {
    base {
        Modifier
            .color(color = Theme.Orange.rgb)
            .transition(Transition.of(property = "color", duration = 200.ms))
    }
    hover {
        Modifier
            .color(color = Theme.Primary.rgb)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val MainImageStyle = CssStyle {
    base {
        Modifier
            .styleModifier {
                filter { grayscale(0.percent) }
            }
            .transition(Transition.of(property = "filter", duration = 200.ms))
    }
    hover {
        Modifier.styleModifier {
            filter { grayscale(100.percent) }
        }
    }
}