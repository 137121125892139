package com.ahmedadeltito.website.sections

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.components.ContactForm
import com.ahmedadeltito.website.components.SectionTitle
import com.ahmedadeltito.website.models.Section
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.util.Constants.SECTION_WIDTH
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun ContactSection() {
    Box(
        modifier = Modifier
            .id(Section.Contact.id)
            .maxWidth(SECTION_WIDTH.px)
            .padding(topBottom = 80.px)
            .classNames("section-container"),
        contentAlignment = Alignment.Center
    ) {
        ContactContent()
    }
}

@Composable
fun ContactContent() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint >= Breakpoint.MD) 100.percent else 90.percent),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = if (breakpoint >= Breakpoint.XL) 20.px else 40.px),
            section = Section.Contact,
            alignment = Alignment.CenterHorizontally
        )
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .margin(
                    topBottom = 60.px,
                    leftRight = if (breakpoint >= Breakpoint.XL) 60.px else 20.px
                )
                .borderRadius(10.px)
                .padding(all = 30.px)
                .backgroundColor(Theme.SectionBackground.rgb)
        ) {
            ContactForm()
        }
    }
}