package com.ahmedadeltito.website.components

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.styles.InputStyle
import com.ahmedadeltito.website.styles.MainButtonStyle
import com.ahmedadeltito.website.util.Constants
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun ContactForm() {
    Form(
        action = Constants.CONTACT_FORM_URL,
        attrs = Modifier
            .fillMaxWidth()
            .attrsModifier {
                attr("method", "POST")
            }
            .toAttrs()
    ) {
        Label(
            attrs = Modifier.then(FONT_FAMILY)
                .classNames("form-label")
                .fontWeight(FontWeight.Normal)
                .fontSize(16.px)
                .color(Theme.DarkGray.rgb)
                .toAttrs(),
            forId = "inputName"
        ) {
            Text("Name *")
        }
        Input(
            type = InputType.Text,
            attrs = InputStyle.toModifier()
                .then(FONT_FAMILY)
                .id("inputName")
                .classNames("form-control")
                .fontWeight(FontWeight.Normal)
                .margin(bottom = 20.px)
                .backgroundColor(Theme.LighterGray.rgb)
                .boxShadow(0.px, 0.px, 0.px, 0.px, null)
                .attrsModifier {
                    attr("placeholder", "Full Name")
                    attr("name", "name")
                    attr("required", "true")
                }
                .toAttrs()
        )
        Label(
            attrs = Modifier.then(FONT_FAMILY)
                .classNames("form-label")
                .fontWeight(FontWeight.Normal)
                .fontSize(16.px)
                .color(Theme.DarkGray.rgb)
                .toAttrs(),
            forId = "inputEmail"
        ) {
            Text("Email *")
        }
        Input(
            type = InputType.Email,
            attrs = InputStyle.toModifier().then(FONT_FAMILY)
                .id("inputEmail")
                .classNames("form-control")
                .fontWeight(FontWeight.Normal)
                .margin(bottom = 20.px)
                .backgroundColor(Theme.LighterGray.rgb)
                .boxShadow(0.px, 0.px, 0.px, 0.px, null)
                .attrsModifier {
                    attr("placeholder", "Email Address")
                    attr("name", "email")
                    attr("required", "true")
                }
                .toAttrs()
        )
        Label(
            attrs = Modifier.then(FONT_FAMILY)
                .classNames("form-label")
                .fontWeight(FontWeight.Normal)
                .fontSize(16.px)
                .color(Theme.DarkGray.rgb)
                .toAttrs(),
            forId = "inputMessage"
        ) {
            Text("Message *")
        }
        TextArea(
            attrs = InputStyle.toModifier().then(FONT_FAMILY)
                .id("inputMessage")
                .classNames("form-control")
                .fontWeight(FontWeight.Normal)
                .height(150.px)
                .margin(bottom = 30.px)
                .backgroundColor(Theme.LighterGray.rgb)
                .boxShadow(0.px, 0.px, 0.px, 0.px, null)
                .attrsModifier {
                    attr("placeholder", "Your Message")
                    attr("name", "message")
                    attr("required", "true")
                }
                .toAttrs()
        )
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.BottomEnd,
        ) {
            Button(
                attrs = MainButtonStyle.toModifier().then(FONT_FAMILY)
                    .height(40.px)
                    .width(200.px)
                    .fontWeight(FontWeight.Normal)
                    .border(width = 0.px)
                    .borderRadius(r = 10.px)
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .toAttrs()
            ) {
                Text("Submit")
            }
        }
    }
}