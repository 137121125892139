package com.ahmedadeltito.website.sections

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.components.SocialLinks
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.ahmedadeltito.website.util.Constants.SECTION_WIDTH
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun FooterSection() {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(SECTION_WIDTH.px)
            .padding(topBottom = 60.px)
            .backgroundColor(Theme.Primary.rgb),
        contentAlignment = Alignment.Center
    ) {
        FooterContent()
    }
}

@Composable
fun FooterContent() {
    val breakpoint = rememberBreakpoint()
    if (breakpoint >= Breakpoint.XL) {
        Column(
            modifier = Modifier.fillMaxWidth(if (breakpoint >= Breakpoint.XL) 80.percent else 100.percent)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                ) {
                    P(
                        attrs = Modifier.then(FONT_FAMILY)
                            .margin(top = 0.px, bottom = 0.px)
                            .fontSize(16.px)
                            .fontWeight(FontWeight.Normal)
                            .color(Theme.LighterGray.rgb)
                            .toAttrs()
                    ) {
                        Text("Ahmed, aka Tito")
                    }
                    P(
                        attrs = Modifier.then(FONT_FAMILY)
                            .margin(top = 10.px, bottom = 5.px)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Normal)
                            .color(Theme.LightGray.rgb)
                            .opacity(60.percent)
                            .toAttrs()
                    ) {
                        Text("SWE | Mobile Architect | Speaker | Mentor")
                    }
                }
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.End
                ) {
                    SocialLinks()
                }
            }
            Box(
                modifier = Modifier
                    .height(2.px)
                    .margin(topBottom = 40.px)
                    .fillMaxWidth(if (breakpoint >= Breakpoint.XL) 70.percent else 90.percent)
                    .backgroundColor(Theme.LighterGray.rgb)
                    .borderRadius(50.px)
                    .align(Alignment.CenterHorizontally)
            )
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                P(
                    attrs = Modifier.then(FONT_FAMILY)
                        .margin(top = 0.px, bottom = 0.px)
                        .fontSize(14.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Theme.LighterGray.rgb)
                        .opacity(50.percent)
                        .toAttrs()
                ) {
                    Text("Made by ")
                    Link(
                        modifier = Modifier
                            .color(Theme.LighterGray.rgb)
                            .opacity(50.percent),
                        path = "https://x.com/ahmedadeltito/",
                        text = "@AhmedAdelTito"
                    )
                }
                P(
                    attrs = Modifier.then(FONT_FAMILY)
                        .margin(top = 10.px, bottom = 5.px)
                        .fontSize(12.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Theme.LighterGray.rgb)
                        .opacity(50.percent)
                        .toAttrs()
                ) {
                    Text("Powered by ")
                    Link(
                        modifier = Modifier
                            .color(Theme.LighterGray.rgb)
                            .opacity(50.percent),
                        path = "https://kobweb.varabyte.com/",
                        text = "Kobweb"
                    )
                }
            }
        }
    } else {
        Column(
            modifier = Modifier.fillMaxWidth(if (breakpoint >= Breakpoint.XL) 80.percent else 100.percent),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(top = 0.px, bottom = 0.px, leftRight = 20.px)
                    .fontSize(16.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.LighterGray.rgb)
                    .align(Alignment.CenterHorizontally)
                    .toAttrs()
            ) {
                Text("Ahmed, aka Tito")
            }
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(top = 10.px, bottom = 5.px, leftRight = 20.px)
                    .fontSize(14.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.LightGray.rgb)
                    .opacity(60.percent)
                    .align(Alignment.CenterHorizontally)
                    .toAttrs()
            ) {
                Text("SWE | Mobile Architect | Speaker | Mentor")
            }
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                SocialLinks()
            }
            Box(
                modifier = Modifier
                    .height(2.px)
                    .margin(topBottom = 40.px)
                    .fillMaxWidth(if (breakpoint >= Breakpoint.XL) 70.percent else 90.percent)
                    .backgroundColor(Theme.LighterGray.rgb)
                    .borderRadius(50.px)
                    .align(Alignment.CenterHorizontally)
            )
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(top = 0.px, bottom = 0.px)
                    .fontSize(14.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.LighterGray.rgb)
                    .opacity(50.percent)
                    .toAttrs()
            ) {
                Text("Made by ")
                Link(
                    modifier = Modifier
                        .color(Theme.LighterGray.rgb)
                        .opacity(50.percent),
                    path = "https://x.com/ahmedadeltito/",
                    text = "@AhmedAdelTito"
                )
            }
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .margin(top = 10.px, bottom = 5.px)
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.LighterGray.rgb)
                    .opacity(50.percent)
                    .toAttrs()
            ) {
                Text("Powered by ")
                Link(
                    modifier = Modifier
                        .color(Theme.LighterGray.rgb)
                        .opacity(50.percent),
                    path = "https://kobweb.varabyte.com/",
                    text = "Kobweb"
                )
            }
        }
    }
}