package com.ahmedadeltito.website.components

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.models.Work
import com.ahmedadeltito.website.styles.WorkSectionStyle
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.ahmedadeltito.website.util.Resources
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun WorkCard(work: Work) {
    Link(
        modifier = WorkSectionStyle.toModifier()
            .textDecorationLine(TextDecorationLine.None),
        path = work.link,
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ) {
        Column(
            modifier = Modifier
                .id("columnParent")
                .fillMaxWidth()
                .borderRadius(10.px)
                .padding(bottom = 10.px)
                .backgroundColor(Theme.SectionBackground.rgb)
        ) {
            Box(
                modifier = Modifier
                    .id("boxParent")
                    .fillMaxWidth()
            ) {
                Image(
                    modifier = Modifier
                        .fillMaxWidth()
                        .objectFit(objectFit = ObjectFit.Cover)
                        .borderRadius(topLeft = 10.px, topRight = 10.px),
                    src = work.image,
                    alt = "Work Image"
                )
                Box(
                    modifier = Modifier
                        .id("overlay")
                        .fillMaxHeight()
                        .borderRadius(topLeft = 10.px, topRight = 10.px)
                        .backgroundColor(argb(a = 0.5f, r = 113, g = 48, b = 250)),
                    contentAlignment = Alignment.Center
                ) {
                    Image(
                        modifier = Modifier
                            .id("linkIcon")
                            .size(32.px),
                        src = Resources.Icon.LINK,
                        alt = "Link Icon"
                    )
                }
            }
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .id("workTitle")
                    .fillMaxWidth()
                    .padding(leftRight = 10.px)
                    .margin(topBottom = 10.px)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Normal)
                    .toAttrs()
            ) {
                Text(work.title)
            }
            P(
                attrs = Modifier.then(FONT_FAMILY)
                    .id("workDesc")
                    .fillMaxWidth()
                    .padding(leftRight = 10.px)
                    .margin(topBottom = 0.px)
                    .fontSize(14.px)
                    .fontWeight(FontWeight.Normal)
                    .toAttrs()
            ) {
                Text(work.description)
            }
        }
    }
}