package com.ahmedadeltito.website.components

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.styles.SocialMediaStyle
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.px

@Composable
fun SocialLinks() {
    Link(
        path = "https://x.com/ahmedadeltito",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
        modifier = Modifier
            .backgroundColor(Colors.White)
            .padding(all = 24.px)
            .clip(shape = Circle(radius = 30))
    ) {
        FaTwitter(
            modifier = SocialMediaStyle.toModifier(),
            size = IconSize.LG
        )
    }

    Link(
        path = "https://www.linkedin.com/in/ahmedadeltito/",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
        modifier = Modifier
            .backgroundColor(Colors.White)
            .padding(all = 24.px)
            .clip(shape = Circle(radius = 30))
    ) {
        FaLinkedin(
            modifier = SocialMediaStyle.toModifier(),
            size = IconSize.LG
        )
    }

    Link(
        path = "https://github.com/ahmedadeltito/",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
        modifier = Modifier
            .backgroundColor(Colors.White)
            .padding(all = 24.px)
            .clip(shape = Circle(radius = 30))
    ) {
        FaGithub(
            modifier = SocialMediaStyle.toModifier(),
            size = IconSize.LG
        )
    }

    Link(
        path = "https://instagram.com/t2graphy",
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
        modifier = Modifier
            .backgroundColor(Colors.White)
            .padding(all = 24.px)
            .clip(shape = Circle(radius = 30))
    ) {
        FaInstagram(
            modifier = SocialMediaStyle.toModifier(),
            size = IconSize.LG
        )
    }
}