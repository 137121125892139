package com.ahmedadeltito.website.util

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily

object Constants {
    const val SECTION_WIDTH = 1920
    val FONT_FAMILY = Modifier.fontFamily("Monaco")

    const val CONTACT_FORM_URL = "https://getform.io/f/dfbdf73f-77d9-4869-8df2-91ccb423751b"
}

object Resources {
    object Image {
        const val LOGO = "logo.svg"
        const val MAIN = "main.png"
        const val ADPLIST = "work_adplist.png"
        const val DRIODCON_TALK = "work_driodcon_egypt_talk.png"
        const val DRIODCON_WORKSHOP = "work_driodcon_egypt_workshop.png"
        const val JOB_STACK_2022 = "work_job_stack.png"
        const val JOB_STACK_2021 = "work_job_stack2.png"
        const val FREELANCE_JOB_FAIR = "work_freelance_job_fair.png"
        const val MV = "work_mv.png"
        const val CLEAN_ARCHITECTURE = "work_clean_architecture.png"
        const val SMARTER_UI = "work_smarter_ui.png"
    }

    object Icon {
        const val MOBILE = "mobile.svg"
        const val KMP = "kmp.svg"
        const val CMP = "cmp.svg"
        const val MOCKING_INTERVIEWS = "mocking.svg"
        const val MENTORING = "mentoring.svg"
        const val CONSULTING = "consulting.svg"
        const val LINK = "link_icon.svg"
        const val STAR = "star_icon.svg"
    }
}