package com.ahmedadeltito.website.models

import com.ahmedadeltito.website.util.Resources

enum class Work(
    val image: String,
    val title: String,
    val description: String,
    val link: String,
) {
    ADPList(
        image = Resources.Image.ADPLIST,
        title = "ADPList Mentorship",
        description = "Mentored 160+ people through career coaching, mock interviews, building a career path, creating tailored mobile road maps, and more.",
        link = "https://adplist.org/mentors/ahmed-adel/"
    ),
    DroidConEgyptTalk(
        image = Resources.Image.DRIODCON_TALK,
        title = "Developing Mobile SDKs",
        description = "Focuses on essential aspects of creating robust and user-friendly Mobile SDKs by mentioning some real life use cases and how to implement them.",
        link = "https://docs.google.com/presentation/d/1KM1Ww2_CHx7yhA95nQNKvNuVqzWLHl7k/edit?usp=share_link&ouid=117994601858895571639&rtpof=true&sd=true"
    ),
    DroidConEgyptWorkshop(
        image = Resources.Image.DRIODCON_WORKSHOP,
        title = "The Myth of E2E Testing",
        description = "Explores the various types of software testing in details, with a particular focus on Automation and End-to-End (E2E) testing.",
        link = "https://docs.google.com/presentation/d/1VbdADWYHzFrSGkKgohWjIei8vJxwjMU-/edit?usp=share_link&ouid=117994601858895571639&rtpof=true&sd=true"
    ),
    TalentsArena2022(
        image = Resources.Image.JOB_STACK_2022,
        title = "Server-Driven UI",
        description = "Covers the concept of Server-Driven UI by defining a design paradigm where both data and UI are controlled by the backend.",
        link = "https://drive.google.com/drive/folders/1K36uKJoleGlfaai4it_IGv1beZ7zqRFu?usp=sharing"
    ),
    TalentsArena2021(
        image = Resources.Image.JOB_STACK_2021,
        title = "Scalable Mobile Apps",
        description = "Guides participants through the development of a flexible Android app architecture using a sample movie product idea.",
        link = "https://drive.google.com/drive/folders/1-mlYVO3gXrMt2dFaI5ENPmmChlBaiRSR?usp=sharing"
    ),
    FreelanceJobFair(
        image = Resources.Image.FREELANCE_JOB_FAIR,
        title = "Freelancing Mobile Apps",
        description = "Outlines the process of developing a robust freelancing apps, focusing on essential pre-coding preparations and efficient development practices.",
        link = "https://drive.google.com/drive/folders/1Rfpd_C-kzHlOYLJGygePPp3tzJznDj48?usp=sharing"
    ),
    MV(
        image = Resources.Image.MV,
        title = "A Journey Through MVs",
        description = "Explores the implementation of clean architecture in Android development through various Model-View (MV) patterns.",
        link = "https://drive.google.com/drive/folders/1nz1vuA6YXvFiI7PanHc9hG_rMrleI9mV?usp=drive_link"
    ),
    CleanArchitecture(
        image = Resources.Image.CLEAN_ARCHITECTURE,
        title = "Clean Architecture in Android",
        description = "Provides an in-depth guide on implementing clean architecture in Android apps with a practical example is provided through a sample movie app.",
        link = "https://drive.google.com/drive/folders/16EZKWN8HvcKs8zWq3d1o6pIJ7xtWf6sv"
    ),
    SmarterUI(
        image = Resources.Image.SMARTER_UI,
        title = "Custom UI Components",
        description = "Delves into the creation and implementation of custom UI components from view, view groups to animations in Android development.",
        link = "https://drive.google.com/drive/folders/1Z7DzOvv7a1lxf27yyg_QS55keabZHfkz"
    ),
}