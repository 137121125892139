package com.ahmedadeltito.website.pages

import androidx.compose.runtime.*
import com.ahmedadeltito.website.components.BackToTopButton
import com.ahmedadeltito.website.components.Header
import com.ahmedadeltito.website.components.OverflowMenu
import com.ahmedadeltito.website.models.Section
import com.ahmedadeltito.website.sections.*
import com.ahmedadeltito.website.util.UpdateEffect
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.asList

@Page
@Composable
fun HomePage() {
    var menuOpened by remember { mutableStateOf(false) }

    val sections = remember {
        document.getElementsByClassName(classNames = "section-container").asList()
    }
    var selectedSectionId by remember {
        mutableStateOf(value = Section.Home.id)
    }

    UpdateEffect(selectedSectionId) {
        window.history.replaceState(
            data = null,
            title = "",
            url = "#$selectedSectionId"
        )
    }

    window.onscroll = { _ ->
        sections.forEach { section ->
            val positionInfo = section.getBoundingClientRect()
            val top = window.scrollY
            val offset = positionInfo.top + top - 250
            val height = positionInfo.height
            val id = section.id

            if (top >= offset && top < offset + height) {
                selectedSectionId = id
            }
        }
    }

    Box(modifier = Modifier.fillMaxSize()) {
        Header(
            selectedSectionId = selectedSectionId,
            onMenuClicked = { menuOpened = true }
        )
        Column(
            modifier = Modifier
                .fillMaxSize(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MainSection()
            AboutSection()
            ServiceSection()
            WorkSection()
            TestimonialSection()
            ContactSection()
            FooterSection()
        }
        BackToTopButton()
        if (menuOpened) {
            OverflowMenu(
                selectedSectionId = selectedSectionId,
                onMenuClosed = { menuOpened = false }
            )
        }
    }
}
