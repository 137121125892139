package com.ahmedadeltito.website.components

import androidx.compose.runtime.*
import com.ahmedadeltito.website.models.Section
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.styles.ActiveNavigationHeaderItemStyle
import com.ahmedadeltito.website.styles.NavigationHeaderItemStyle
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.thenIf
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*

@Composable
fun OverflowMenu(
    selectedSectionId: String,
    onMenuClosed: () -> Unit
) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var translateY by remember { mutableStateOf((-100).percent) }
    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(breakpoint) {
        translateY = 0.percent
        opacity = 100.percent
        if (breakpoint > Breakpoint.XL) {
            scope.launch {
                translateY = (-100).percent
                opacity = 0.percent
                delay(500)
                onMenuClosed()
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(2)
            .opacity(opacity)
            .backgroundColor(argb(a = 0.5f, r = 0.0f, g = 0.0f, b = 0.0f))
            .transition(Transition.of(property = "opacity", duration = 500.ms))
            .onClick {
                scope.launch {
                    translateY = (-100).percent
                    opacity = 0.percent
                    delay(500)
                    onMenuClosed()
                }
            }
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .overflow(Overflow.Auto)
                .scrollBehavior(ScrollBehavior.Smooth)
                .backgroundColor(color = Theme.SectionBackground.rgb)
                .translateY(ty = translateY)
                .transition(Transition.of(property = "translate", duration = 500.ms)),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(leftRight = 20.px, topBottom = 12.px)
                    .zIndex(2)
                    .position(position = Position.Fixed)
                    .backgroundColor(color = Theme.SectionBackground.rgb),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                WebsiteLogo()
                FaXmark(
                    modifier = Modifier
                        .backgroundColor(color = Theme.LighterGray.rgb)
                        .onClick {
                            scope.launch {
                                translateY = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onMenuClosed()
                            }
                        },
                    size = IconSize.XXL
                )
            }
            Section.entries.forEachIndexed { index, section ->
                Link(
                    modifier = Modifier.then(FONT_FAMILY)
                        .fontSize(16.px)
                        .fontWeight(FontWeight.Normal)
                        .textDecorationLine(TextDecorationLine.None)
                        .margin(topBottom = 20.px)
                        .onClick {
                            scope.launch {
                                translateY = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onMenuClosed()
                            }
                        },
                    variant = NavigationHeaderItemStyle.thenIf(
                        condition = selectedSectionId == section.id,
                        other = ActiveNavigationHeaderItemStyle
                    ),
                    path = section.path,
                    text = section.title,
                    openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
                )
                if (index < Section.entries.size - 1) {
                    Box(
                        modifier = Modifier
                            .height(2.px)
                            .fillMaxWidth()
                            .backgroundColor(Theme.DarkGray.rgb)
                            .align(Alignment.CenterHorizontally)
                    )
                }
            }
        }
    }
}