package com.ahmedadeltito.website.components

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.models.Section
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.styles.ActiveNavigationHeaderItemStyle
import com.ahmedadeltito.website.styles.MainButtonStyle
import com.ahmedadeltito.website.styles.NavigationHeaderItemStyle
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.ahmedadeltito.website.util.Resources
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.thenIf
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button

@Composable
fun Header(
    selectedSectionId: String,
    onMenuClicked: () -> Unit
) {
    val breakpoint = rememberBreakpoint()
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(
                leftRight = if (breakpoint >= Breakpoint.XL) 80.px else 20.px,
                topBottom = 12.px
            )
            .zIndex(2)
            .position(position = Position.Fixed)
            .backgroundColor(color = Theme.SectionBackground.rgb),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        WebsiteLogo()
        if (breakpoint >= Breakpoint.XL) {
            RightSide(selectedSectionId = selectedSectionId)
        } else {
            FaBars(
                modifier = Modifier
                    .margin(all = 15.px)
                    .borderRadius(r = 12.px)
                    .border(
                        width = 2.px,
                        style = LineStyle.Solid,
                        color = Theme.LighterGray.rgb
                    )
                    .backgroundColor(color = Theme.LighterGray.rgb)
                    .onClick {
                        onMenuClicked()
                    },
                size = IconSize.XL
            )
        }
    }
}

@Composable
fun WebsiteLogo() {
    Image(
        modifier = Modifier
            .height(50.px)
            .padding(leftRight = 4.px),
        src = Resources.Image.LOGO,
        description = "Logo Image"
    )
}

@Composable
fun RightSide(
    selectedSectionId: String,
) {
    Row(
        horizontalArrangement = Arrangement.End,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            modifier = Modifier
                .height(40.px)
                .borderRadius(r = 20.px)
                .border(width = 0.px)
                .backgroundColor(color = Theme.LighterGray.rgb)
                .padding(leftRight = 10.px)
                .margin(leftRight = 10.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Section.entries.forEach { section: Section ->
                Link(
                    modifier = Modifier.then(FONT_FAMILY)
                        .padding(right = 14.px, left = 14.px)
                        .fontSize(value = 16.px)
                        .fontWeight(value = FontWeight.Normal)
                        .textDecorationLine(textDecorationLine = TextDecorationLine.None),
                    variant = NavigationHeaderItemStyle.thenIf(
                        condition = selectedSectionId == section.id,
                        other = ActiveNavigationHeaderItemStyle
                    ),
                    path = section.path,
                    text = section.title
                )
            }
        }
        Button(
            attrs = MainButtonStyle.toModifier()
                .height(40.px)
                .border(width = 0.px)
                .borderRadius(r = 50.px)
                .cursor(cursor = Cursor.Pointer)
                .toAttrs()
        ) {
            Link(
                modifier = Modifier.then(FONT_FAMILY)
                    .color(color = Colors.White)
                    .fontSize(value = 16.px)
                    .fontWeight(value = FontWeight.Normal)
                    .textDecorationLine(textDecorationLine = TextDecorationLine.None),
                text = "My Blog",
                path = "https://medium.com/@ahmedadeltito"
            )
        }
    }
}
