package com.ahmedadeltito.website.components

import androidx.compose.runtime.Composable
import com.ahmedadeltito.website.models.Testimonial
import com.ahmedadeltito.website.models.Theme
import com.ahmedadeltito.website.styles.PersonalWebsiteStyle
import com.ahmedadeltito.website.styles.SocialMediaStyle
import com.ahmedadeltito.website.styles.TestimonialCardStyle
import com.ahmedadeltito.website.util.Constants.FONT_FAMILY
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaLinkedin
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun TestimonialCard(testimonial: Testimonial) {
    Column(
        modifier = TestimonialCardStyle.toModifier()
            .fillMaxWidth()
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .padding(all = 20.px),
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.Top
        ) {
            Column {
                P(
                    attrs = Modifier.then(FONT_FAMILY)
                        .fillMaxWidth()
                        .margin(topBottom = 0.px)
                        .fontSize(18.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Theme.Secondary.rgb)
                        .toAttrs()
                ) {
                    Text(testimonial.fullName)
                }
                P(
                    attrs = Modifier.then(FONT_FAMILY)
                        .fillMaxWidth()
                        .margin(topBottom = 0.px)
                        .fontSize(16.px)
                        .fontWeight(FontWeight.Normal)
                        .color(Theme.DarkGray.rgb)
                        .toAttrs()
                ) {
                    Text(testimonial.relationship)
                }
                RatingBar(
                    modifier = Modifier.margin(top = 5.px, bottom = 10.px)
                )
            }
            Link(
                path = testimonial.personalWebsite,
                openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                modifier = PersonalWebsiteStyle.toModifier()
                    .backgroundColor(Colors.White)
                    .align(Alignment.Top)
            ) {
                FaLinkedin(
                    modifier = SocialMediaStyle.toModifier(),
                    size = IconSize.XL
                )
            }
        }
        P(
            attrs = Modifier.then(FONT_FAMILY)
                .fillMaxWidth()
                .margin(topBottom = 0.px)
                .fontSize(14.px)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Secondary.rgb)
                .opacity(80.percent)
                .toAttrs()
        ) {
            Text(testimonial.review)
        }
    }
}