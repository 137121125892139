package com.ahmedadeltito.website.models

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb

enum class Theme(
    val rgb: CSSColorValue
) {
    Primary(rgb = rgb(r = 52, g = 58, b = 64)),
    Secondary(rgb = rgb(r = 113, g = 48, b = 250)),
    Orange(rgb = rgb(r = 253, g = 126, b = 20)),
    Black(rgb = rgb(r = 0, g = 0, b = 0)),
    DarkGray(rgb = rgb(r = 100, g = 100, b = 100)),
    LightGray(rgb = rgb(r = 237, g = 237, b = 237)),
    LighterGray(rgb = rgb(r = 249, g = 249, b = 249)),
    SectionBackground(rgb = rgb(r = 240, g = 245, b = 255))
}